<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- Start Header Area -->
    <div v-if="data">
      <Header>
        <img
          slot="logo"
          src="../../assets/images/logo/Logo-Inflow-Hor-BB@2x.png"
          style="max-width:80%; max-height:80%"
        />
      </Header>

      <!-- End Header Area -->
      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="8"
        v-if="data && data.title_background.data !== null && 'medium' in data.title_background.data.attributes.formats"
        :style="{
          backgroundImage: 'url(' + data.title_background.data.attributes.formats.medium.url + ')',
        }"
      >
        <!-- Start Breadcrump Area  -->

        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="blog-single-page-title text-center pt--100">
                <h2
                  class="heading-title theme-gradient"
                  color="white"
                  v-if="data"
                >
                  {{ data.title }}
                </h2>
                <ul class="blog-meta d-flex justify-center align-center">
                  <li class="d-flex" v-for="(meta, i) in metaList" :key="i">
                    <v-btn
                      :loading="loadingPostIcon"
                      icon
                      dark
                      @click="sumbitLike()"
                      ><span v-html="iconSvg(meta.icon)"></span></v-btn
                    >{{ meta.text }}
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="8"
        v-else-if="data && data.title_background.data !== null && 'small' in data.title_background.data.attributes.formats"
        :style="{
          backgroundImage: 'url(' + data.title_background.data.attributes.formats.small.url + ')',
        }"
      >
        <!-- Start Breadcrump Area  -->

        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="blog-single-page-title text-center pt--100">
                <h2
                  class="heading-title theme-gradient"
                  color="white"
                  v-if="data"
                >
                  {{ data.title }}
                </h2>
                <ul class="blog-meta d-flex justify-center align-center">
                  <!-- <li class="d-flex" v-for="(meta, i) in metaList" :key="i">
                    <v-btn
                      :loading="loadingPostIcon"
                      icon
                      dark
                      @click="sumbitLike()"
                      ><span v-html="iconSvg(meta.icon)"></span></v-btn
                    >{{ meta.text }}
                  </li> -->
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div
        class="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
        data-black-overlay="8"
        v-else
      >
        <!-- Start Breadcrump Area  -->

        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="blog-single-page-title text-center pt--100">
                <h2
                  class="heading-title theme-gradient"
                  color="white"
                  v-if="data"
                >
                  {{ data.title }}
                </h2>
                <ul class="blog-meta d-flex justify-center align-center">
                  <li class="d-flex" v-for="(meta, i) in metaList" :key="i">
                    <v-btn
                      :loading="loadingPostIcon"
                      icon
                      dark
                      @click="sumbitLike()"
                      ><span v-html="iconSvg(meta.icon)"></span></v-btn
                    >{{ meta.text }}
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- End Breadcrump Area  -->

      <!-- Start Blog Details Area  -->
      <div class="rn-blog-details pt--110 pb--70 bg_color--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner-wrapper">
                <div class="inner">
                  <div v-if="data" v-html="compiledMarkdown(data.body)"></div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <div v-if="data.tags.data.length > 0">
              <v-chip
                class="ma-1"
                v-for="(chip, chipIndex) in data.tags.data"
                :key="chipIndex"
              >
                {{ chip.attributes.name }}
              </v-chip>
            </div>
          </v-row>
        </v-container>
      </div>
      <!-- End Blog Details Area  -->
      <!-- <div class="blog-comment-form pb--120 bg_color--1" v-if="data">
        <v-container> -->
          <!-- <v-row>
            <v-col cols="12">
              <div class="section-title section-title--2 text-center">
                <h2 class="heading-title">Productos relacionados</h2>
              </div>
            </v-col>
          </v-row> -->
          <!-- End .row -->
          <!-- <PortfolioFour
            :portfolioContentComponent="data.productos"
            :urlComponent="'/service-details/productos/'"
          /> -->
        <!-- </v-container>
      </div> -->
      <!-- Start Comment Form  -->
      <!-- <div class="blog-comment-form pb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="inner">
                <h3 class="title mb--40 fontWeight500">Deja un comentario</h3>
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(onSubmit)">
                    <v-row>
                      <v-col lg="6" md="12" cols="12">
                        <div class="rnform-group">
                          <ValidationProvider
                            name="name"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label>
                              <input
                                type="text"
                                v-model="formData.name"
                                placeholder="Nombre"
                              />
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                        <div class="rnform-group">
                          <ValidationProvider
                            name="email"
                            rules="required|email"
                            v-slot="{ errors }"
                          >
                            <label>
                              <input
                                type="text"
                                rules="required|email"
                                v-model="formData.email"
                                placeholder="Email"
                              />
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                        <div class="rnform-group">
                          <ValidationProvider
                            name="website"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label>
                              <input
                                type="text"
                                v-model="formData.subject"
                                placeholder="Institucion"
                              />
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col lg="6" md="12" cols="12">
                        <div class="rnform-group">
                          <ValidationProvider
                            name="comment"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <label>
                              <textarea
                                v-model="formData.message"
                                placeholder="Comentario"
                              ></textarea>
                              <span class="inpur-error">{{ errors[0] }}</span>
                            </label>
                          </ValidationProvider>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="blog-submit-btn">
                          <button
                            class="rn-button-style--2 btn_solid"
                            type="submit"
                            value="submit"
                          >
                            ENVIAR
                          </button>
                        </div>
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div> -->
      <!-- End Comment Form  -->

      <Footer />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
//import Dialog from "../../components/dialog/Dialog.vue";
import feather from "feather-icons";
import PortfolioFour from "../../components/portfolio/PortfolioFour.vue";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";

import marked from "marked";
export default {
  components: {
    Header,
    Footer,
    ValidationObserver,
    ValidationProvider,
    PortfolioFour,
    //Dialog,
  },
  data() {
    return {
      loadingPostIcon: false,
      data: null,
      text: null,
      overlay: false,
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      metaList: [],
      index: null,
    };
  },
  mounted() {
    this.getDataBlog();
  },
  methods: {
    //No se envia el Id del blog asociado al comentario, observar contenidos enviados
    // async sumbitLike() {
    //   this.loadingPostIcon = true;
    //   try {
    //     const res = await axios.put(
    //       `${this.$apiUrl}/blogs/${this.$route.params.id}`,
    //       { likes: this.data.likes + 1 }
    //     );
    //     this.loadingPostIcon = false;
    //   } catch (err) {
    //     console.log(err);
    //     this.loadingPostIcon = false;
    //   }
    // },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    async onSubmit() {
      try {
        var parseJson = JSON.parse(JSON.stringify(this.data.comentario));
        var commentsArray = parseJson;
        commentsArray.push({
          id: this.data.comentario.length + 1,
          comentario: this.formData.message,
        });
        const res = await axios.put(
          `${this.$apiUrl}/api/posts/?filters\[Slug\][$eq]=${this.$route.params.slug}&populate=*`,
          { title: comentario }
        );
      } catch (err) {
        console.log(err);
      }
    },
    compiledMarkdown(texto) {
      return marked(texto);
    },
    async getDataBlog() {
      try {
        const r = await axios(`${this.$apiUrl}/api/posts/?filters\[slug\]\[$eq\]=${this.$route.params.slug}&populate=*`);
        // const r = await axios(`${this.$apiUrl}/api/posts/${this.$route.params.id}&populate=*`);
        this.data = r.data.data[0].attributes;
        // this.text = this.data.body.split("\n\n");
        this.metaList.push(
          {
            icon: "clock",
            text: this.data.date,
          },
          // {
          //   icon: "user",
          //   text: this.data.persona.name,
          // },
          // {
          //   icon: "message-circle",
          //   text: this.data.comentarios.length + " Comentarios",
          // },
          {
            icon: "heart",
            text: this.data.likes + " Likes",
          }
        );
        this.overlay = false;
      } catch (err) {
        console.log(err);

        this.overlay = false;
      }
    },
  },
};
</script>
